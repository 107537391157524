<template>
  <strong
    class="hidden-md-and-up order-info-title"
    v-html="$t(title) + '<br />'"
    @click="clicked"
  >
  </strong>
</template>
<style></style>
<script>
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "OrderDetailQuantity",
  mixins: [clickHandler],
  props: {
    title: { type: String, required: true }
  }
};
</script>
