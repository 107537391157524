<template>
  <div class="orders-table mt-3">
    <v-row no-gutters class="table-header hidden-sm-and-down text-uppercase">
      <v-col cols="2" class="hidden-sm-and-down">
        {{ $t("orders.header.orderId") }}
      </v-col>
      <v-col cols="2" class="hidden-sm-and-down">
        {{ $t("orders.header.orderDate") }}
      </v-col>
      <v-col cols="7" md="2">
        {{ $t("orders.header.orderDeliveryDate") }}
      </v-col>
      <v-col cols="3" md="2">
        {{ $t("orders.header.orderStatus") }}
      </v-col>
      <v-col cols="7" md="2">
        {{ $t("orders.header.orderDelivery") }}
      </v-col>
      <v-col cols="2">
        {{ $t("orders.header.orderTotal") }}
      </v-col>
    </v-row>

    <v-list elevation="0" class="orders-list">
      <v-list-item
        v-for="order in orders"
        :key="order.orderId"
        v-bind:to="'/profile/orders/' + order.orderId"
      >
        <!-- :class="{ zebra: index % 2 == 0 }" -->
        <v-row class="order-card">
          <!-- N° ordine -->
          <!-- <v-col cols="6" md="2" class="hidden-sm-and-down"> -->
          <v-col cols="6" md="2" class="order-card-info">
            <OrderInfoTitle :title="'orders.header.orderId'" />
            <strong class="order-id">#{{ order.orderId }}</strong>
            <div class="delivery-type d-flex">
              <v-tooltip
                v-if="order.user.userTypeId && order.user.userTypeId == 2"
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <img
                    class="agent-icon"
                    src="/img/agent.svg"
                    alt="Agente"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                {{ $t("orders.list.agentTooltip") }}
              </v-tooltip>
              <img
                class="delivery-order-img"
                :src="
                  '/services-icons/' +
                    $t(
                      'orders.list.decodeAddressTypeImg.' +
                        order.shippingAddress.addressType
                    ) +
                    '.svg'
                "
                alt="Delivery service"
              />
            </div>
          </v-col>
          <!-- data ordine -->
          <v-col cols="6" md="2" class="order-card-info">
            <OrderInfoTitle :title="'orders.header.orderDate'" />
            <span>
              {{ $dayjs(order.addDate).format("D MMMM YYYY") }}
            </span>
          </v-col>
          <!-- data consegna ritiro -->
          <v-col cols="6" md="2" class="order-card-info">
            <OrderInfoTitle :title="'orders.header.orderDeliveryDate'" />
            <span class="text-capitalize d-flex flex-column">
              <span class="timeslot">
                {{
                  $dayjs(
                    order.timeslot.date + " " + order.timeslot.beginTime,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).format("D MMMM")
                }}
                <br />
                {{ order.timeslot.name }}
              </span>
            </span>
          </v-col>
          <!-- stato -->
          <v-col cols="6" md="2" class="order-card-info">
            <OrderInfoTitle :title="'orders.header.orderStatus'" />
            <OrderStatusBadge v-bind:order="order" />
          </v-col>
          <!-- consegna ritiro -->
          <v-col cols="6" md="2">
            <OrderInfoTitle :title="'orders.header.orderDelivery'" />
            <span class="text-capitalize d-flex flex-column">
              <span class="address">
                {{
                  $t(
                    "navbar.address." +
                      order.shippingAddress.addressTypeId +
                      ".format",
                    order.shippingAddress
                  )
                }}
              </span>
            </span>
          </v-col>
          <!-- totale -->
          <v-col cols="6" md="1" class="order-card-info">
            <OrderInfoTitle :title="'orders.header.orderTotal'" />
            <strong>
              {{
                $n(
                  isDelivered(order)
                    ? order.deliveredGrossTotal
                    : order.grossTotal,
                  "currency"
                )
              }}</strong
            >
          </v-col>
          <!-- riordina -->
          <v-col
            cols="12"
            md="1"
            class="actions-order justify-end order-card-info"
          >
            <!-- class="actions-order justify-end order-card-info d-md-flex flex-md-column mb-md-1" -->
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <!-- <div class="mb-md-1"> -->
                  <!-- style="width: 100%" -->
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    depressed
                    @click.stop.prevent="editOrder(order.orderId)"
                    color="primary"
                    v-if="order.isEditable"
                  >
                    <v-icon>$edit</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>{{ $t("orders.list.modify") }}</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <div class="ml-1">
                  <!-- <div class="ml-1 ml-md-0"> -->
                  <!-- style="width: 100%" -->
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    depressed
                    color="secondary"
                    @click.stop.prevent="deleteOrder(order.orderId)"
                    v-if="order.isDeletable"
                  >
                    <v-icon>$delete</v-icon>
                  </v-btn>
                </div>
              </template>
              <span>{{ $t("orders.list.cancel") }}</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  color="primary"
                  v-if="!order.isEditable"
                  @click.stop.prevent="addAllToCart(order.orderId)"
                >
                  <span class="hidden-md-and-up">ORDINA DI NUOVO</span>
                  <v-icon>$reorder</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("orders.list.addAllToCart") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<style scoped>
.address-image {
  width: 30px;
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge";
import OrderInfoTitle from "@/components/orders/OrderInfoTitle";
// import OrderService from "~/service/orderService";
import { mapState, mapGetters } from "vuex";
export default {
  name: "OrdersTable",
  props: {
    orders: { type: Array, required: true },
    ordersAllUsers: { type: Boolean, default: false }
  },
  components: {
    OrderStatusBadge,
    OrderInfoTitle
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    timeslot() {
      return this.getItemByProductId(this.product.productId);
    },
    quantity() {
      return this.item ? this.item.quantity : 0;
    },
    canIRateOrder() {
      return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    }
  },
  methods: {
    rateOrder(orderId) {
      this.$router.push("/order/" + orderId);
    },
    async deleteOrder(orderId) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });
      if (res) {
        this.$emit("delete", orderId);
      }
    },
    editOrder(orderId) {
      this.$emit("editOrder", orderId);
    },
    addAllToCart(orderId) {
      this.$emit("addAllToCart", orderId);
    },
    isDelivered(order) {
      // return false;
      //decommentare per mostrare i campi delivered
      return (
        // this.order.orderStatusId == 6 ||
        order.orderStatusId == 8 || order.orderStatusId == 18
      );
    }
  },
  mounted() {}
};
</script>
